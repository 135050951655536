import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from './../defaults'
import { useContext, useEffect, useState } from 'react';
import { TournamentConfig } from '../interfaces';
import './../App.css';
import { AuthContext } from './../auth/AuthContext';


const columns: GridColDef[] = [
    { field: 'uid',
      headerName: 'UID',
      width: 200 
    },
    {
      field: 'entry_price',
      headerName: 'Entry Price',
      width: 150,
      editable: true,
    },
    {
      field: 'prizes',
      headerName: 'Prizes',
      type: 'list',
      width: 200,
      editable: true,
    },
    {
        field: 'max_player_count',
        headerName: 'Max Player Count',
        width: 150,
        editable: true,
    },
    {
        field: 'active',
        headerName: 'Active',
        width: 150,
        editable: true,
        type: 'boolean'
    }
  ];

  

function Tournament() {
    const [tournamentConfigs, setTournamentCongfigs] = useState<TournamentConfig[]>([]);
    const authContext = useContext(AuthContext);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const user = authContext.user;
          if (user === null) {
            throw new Error('Need a an admin user object to make admin calls');
          }
          const idToken = await user.getIdToken()
          const response = await axios.get('/internal/tournaments', { headers: {
            "Authorization" : `Bearer ${idToken}`
          } } );
          setTournamentCongfigs(response.data);
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };
      fetchData();
    }, [authContext.user]);
  
    return (
      <Box sx={{ height: 400, width: '100%' }}>
        <Typography variant="h3">Users</Typography>
        <DataGrid getRowId={(row) => row.uid}
          rows={tournamentConfigs}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    );
  }
  
  export default Tournament;
  