import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function ProtectedRoute({ children }: any) {
    const authContext = useContext(AuthContext);
    // console.log(authContext.user)
    if (authContext.isLoading) {
        return <div>Loading...</div>; // Or any loading component
      }

    if (!authContext.user) {
        return <Navigate to="/"/>;
    }

    return children;
};

export default ProtectedRoute;