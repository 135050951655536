import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import PageNotFound from './PageNotFound';
import AuthProvider from './auth/AuthContext';
import AuthHandler from './auth/AuthHandler';
import ProtectedRoute from './auth/ProtectedRoute';
import Games from './games/Games';
import Livestream from './livestream/Agora';
import Questions from './questions/Questions';
import Tournament from './tournaments/Tournaments';
import UserPage from './users/UserPage';
import Users from './users/Users';
import { Home } from './Home';


function App() {
  return (
    <AuthProvider>
      <Router>
        <AuthHandler>
        <Header />
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/users" element={<ProtectedRoute><Users/></ProtectedRoute>}/>
          <Route path="/users/:userId" element={<ProtectedRoute><UserPage/></ProtectedRoute>}/>
          <Route path="/games" element={<ProtectedRoute><Games/></ProtectedRoute>}/> 
          <Route path="/livestream" element={<ProtectedRoute><Livestream/></ProtectedRoute>}/> 
          <Route path="/tournaments" element={<ProtectedRoute><Tournament/></ProtectedRoute>}/>
          <Route path="/games/:gameId/questions" element={<ProtectedRoute><Questions/></ProtectedRoute>}/>
          <Route path="*" element={<ProtectedRoute><PageNotFound/></ProtectedRoute>} />
        </Routes>
        </AuthHandler>
      </Router>
    </AuthProvider>
  );
}

export default App;
