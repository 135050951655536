import { useEffect, useState } from "react";
import type {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  CameraVideoTrackInitConfig,
} from "agora-rtc-sdk-ng/esm";

import {
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged
} from "agora-rtc-sdk-ng/esm"
import axios from "./../defaults";

onCameraChanged((device) => {
  console.log("onCameraChanged: ", device);
})
onMicrophoneChanged((device) => {
  console.log("onMicrophoneChanged: ", device);
})

type AgoraDetails = {
    token: string,
    channelId: string,
    userInt: number
}

const client: IAgoraRTCClient = createClient({
  mode: "rtc",
  codec: "vp8",
});
let audioTrack: IMicrophoneAudioTrack;
let videoTrack: ICameraVideoTrack;
function Livestream() {
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);

  const turnOnCamera = async (flag?: boolean) => {
    flag = flag ?? !isVideoOn;
    setIsVideoOn(flag);

    if (videoTrack) {
      return videoTrack.setEnabled(flag);
    }
    const videoEncoderConfig = {
      encoderConfig: {
        frameRate: 30,
        height: 720,
        width: 360
      }
    } as CameraVideoTrackInitConfig;
    videoTrack = await createCameraVideoTrack(videoEncoderConfig);
    videoTrack.play("camera-video");
  };

  const turnOnMicrophone = async (flag?: boolean) => {
    flag = flag ?? !isAudioOn;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    audioTrack.play();
  };

  const [isJoined, setIsJoined] = useState(false);
  const [agora, setAgora] = useState<AgoraDetails>(); 
  useEffect(() => {
    const fetchData = async () => {
        const response = await axios.get(`/livestream/getBroadcastToken`, {
            headers: {
                "parlay-user-id": 'HESENktnbgqYPvBSc15q'
            }
        });
        setAgora(response.data);
    }
    fetchData();
  }, []);

  const joinChannel = async () => {

    if (isJoined) {
      await leaveChannel();
    }

    client.on("user-published", onUserPublish);
    console.log("agora", agora)
    await client.join(
      'ca3e583dd51042758d017a74a1dfb88a',
      agora?.channelId ?? "",
      agora?.token ?? "",
      agora?.userInt ?? ""
    );
    setIsJoined(true);
  };

  const leaveChannel = async () => {
    setIsAudioPubed(false);
    setIsVideoPubed(false);
    await client.leave();

    setIsJoined(false);
  };

  const onUserPublish = async (
    user: IAgoraRTCRemoteUser
  ) => {
      const remoteTrackVideo = await client.subscribe(user, 'video');
      const remoteTrackAudio = await client.subscribe(user, 'audio');
      remoteTrackVideo.play("remote-video");
      remoteTrackAudio.play();
      setIsVideoSubed(true);
    
  };

  const togglePublish = async () => {
    if (isJoined) {
      await turnOnCamera(false)
      await turnOnMicrophone(false);

      await client.unpublish(videoTrack);
      await client.unpublish(audioTrack);

      leaveChannel();
    } else {
      await turnOnCamera(true);
      await turnOnMicrophone(true);

      if (!isJoined) {
        await joinChannel();
      }
      await client.publish(videoTrack);
      await client.publish(audioTrack);
      setIsVideoPubed(true);
      setIsAudioPubed(true);
    }
  };

  return (
    <>
      <div className="left-side">
        <h3>Pleat check your camera / microphone!</h3>
        <div className="buttons">
          <button
            onClick={() => turnOnCamera()}
            disabled={isVideoPubed}
            className={isVideoOn ? "button-on" : ""}
          >
            Turn {isVideoOn ? "off" : "on"} camera
          </button>
          <button
            onClick={() => turnOnMicrophone()}
            disabled={isAudioPubed}
            className={isAudioOn ? "button-on" : ""}
          >
            Turn {isAudioOn ? "off" : "on"} Microphone
          </button>
        </div>
        <div className="buttons">
          <button
            onClick={togglePublish}
          >
            {isJoined ? "Stop Broadcasting": "Start Broadcasting"}
          </button>
        </div>
      </div>
      <div className="right-side">
        <video id="camera-video" hidden={isVideoOn ? false : true}></video>
        <video id="remote-video" hidden={isVideoSubed ? false : true}></video>
      </div>
    </>
  );
}

export default Livestream;