import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from './../defaults'
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { User } from '../interfaces';
import './../App.css';
import SendMessageModal from './SendMessageModal';
import { AuthContext } from '../auth/AuthContext';

export const userCache: Map<string, User> = new Map();

const columns: GridColDef[] = [
  { field: 'uid',
    headerName: 'UID',
    width: 200,
    renderCell: (params) => {
      return (
        <Link to={`/users/${params.row.uid}`}>{params.row.uid}</Link>
      )
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: true,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    type: 'number',
    width: 200,
    editable: true,
  },
  {
    field: 'message',
    headerName: 'Send Message',
    width: 250,
    renderCell: (params) => {
      console.log(params.row)
      return (
        (params.row.deviceToken) ? <SendMessageModal user={params.row}></SendMessageModal> : <></>
      )
    }
  }
];

function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = authContext.user;
        if (user === null) {
          throw new Error('Need a an admin user object to make admin calls');
        }
        const idToken = await user.getIdToken();
        console.log(`Axios base URL: ${axios.defaults.baseURL}`);
        const response = await axios.get<User[]>(`/internal/users/all`,{ headers: {
          "Authorization" : `Bearer ${idToken}`
        } } );
        const data = response.data
        data.forEach(user => {
          userCache.set(user.uid, user);
        })
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [authContext.user]);
  
  if (loading) {
    return <p>Loading...</p>;
}
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h3">Users</Typography>
      <DataGrid getRowId={(row) => row.uid}
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[15]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default Users;
