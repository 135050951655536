import { useEffect, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from './AuthContext';

function AuthHandler({children}: any) {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
  
    useEffect(() => {
        if (!authContext.user) return; // Make sure authContext is not null
    
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const result = await user.getIdTokenResult()
                if (result.claims.admin) {
                        authContext.setUser(user);
                        <Navigate to="/" />;
                    }
                }
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate, authContext]);

    return children;
};
export default AuthHandler;