import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import axios from './../defaults';

function getNextGameTime() {
    const now = new Date();
    const easternTimeOffset = -5; // Eastern Standard Time is UTC-5
    const currentHourInEastern = now.getUTCHours() + easternTimeOffset;

    // Convert local time to Eastern Time
    let easternDateTime = new Date(now.getTime() + easternTimeOffset * 3600 * 1000);

    if (now.getUTCMonth() > 2 && now.getUTCMonth() < 11) {
        // Adjust for Eastern Daylight Time (EDT) from March to November (approximation)
        easternDateTime = new Date(now.getTime() + (easternTimeOffset + 1) * 3600 * 1000);
    }

    // Reset minutes and seconds for comparison
    easternDateTime.setMinutes(0, 0, 0);

    // Weekday logic (Monday = 1, Sunday = 0 or 7)
    if (easternDateTime.getDay() >= 1 && easternDateTime.getDay() <= 5) { // Weekdays
        if (currentHourInEastern >= 19) { // After 7 PM Eastern
            easternDateTime.setDate(easternDateTime.getDate() + (easternDateTime.getDay() === 5 ? 2 : 1)); // Next day, or Monday if today is Friday
        }
        easternDateTime.setHours(19); // Set to 7 PM Eastern
    } else { // Weekends
        if (easternDateTime.getDay() === 0 && currentHourInEastern >= 12) { // After 12 PM Eastern on Sunday
            easternDateTime.setDate(easternDateTime.getDate() + 1); // Next day (Monday)
            easternDateTime.setHours(19); // Set to 6 PM Eastern
        } else {
            easternDateTime.setDate(easternDateTime.getDate() + (easternDateTime.getDay() === 6 ? 1 : 0)); // Next day if Saturday
            easternDateTime.setHours(12); // Set to 12:30 PM Eastern
        }
    }

    return (easternDateTime.getTime() / 1000).toString();
}

function getNextStartTime() {
    // Create a date object for the current time in Eastern Time
    const now = new Date();
    const ETOffset = -5 * 60; // Eastern Standard Time offset in minutes
    const localOffset = now.getTimezoneOffset(); // Local timezone offset in minutes
    const totalOffset = ETOffset - localOffset; // Total offset in minutes

    // Convert current time to Eastern Time
    now.setMinutes(now.getMinutes() + totalOffset);

    // Set the time to 5 AM
    const next5AM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 5, 0, 0, 0);

    // If it's already past 5 AM today in Eastern Time, set for 5 AM next day
    if (now.getTime() > next5AM.getTime()) {
        next5AM.setDate(next5AM.getDate() + 1);
    }

    // Convert back to local time before converting to Unix timestamp
    next5AM.setMinutes(next5AM.getMinutes() - totalOffset);

    // Convert to Unix time in seconds
    return (Math.floor(next5AM.getTime() / 1000)).toString();
}

interface GameModalProps {
    onSubmit: () => void
}


function GameModal({onSubmit}: GameModalProps)  {
    const [isOpen, setIsOpen] = useState(false);
    const [league, setLeague] = useState('NBA');
    const [title, setTitle] = useState('');
    const [startTime, setStartTime] = useState(getNextStartTime());
    const [closeTime, setCloseTime] = useState(getNextGameTime());
    const [lineupSlots, setLineupSlots] = useState(0);
    const [entries, setEntries] = useState(0);
    const [entryPrice, setEntryPrice] = useState(0);
    const [prize, setPrize] = useState(0);
    const authContext = useContext(AuthContext);
    const handleOpen = () => {
        setIsOpen(true)
    };
    const handleClose = () => setIsOpen(false);
    const handleLeagueChange = async (event: React.MouseEvent<HTMLElement>, league: string) => {
        setLeague(league);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const user = authContext.user;
            if (user === null) {
                throw new Error('Need a an admin user object to make admin calls');
            }
            const idToken = await user.getIdToken();
            const data = {
                "startTime": +(startTime),
                "closeTime": +(closeTime),
                "league": league,
                "title": title,
                "lineupSlots": lineupSlots,
                "entries": entries,
                "entryPrice": entryPrice,
                "prize": prize
                
              };
             await axios.post(`/internal/games/create`, data, {
                headers: {
                    "Authorization" : `Bearer ${idToken}`
                }
             })
          } catch (error) {
            console.error('Error fetching data: ', error);
          } finally {
            onSubmit()
            handleClose();
          }
        // Your logic to create a new game with the input (gameName)
        
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Create Game
            </Button>
            <Modal open={isOpen} onClose={handleClose}>
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: 300,
                    }}
                >
                    <Typography variant="h6" gutterBottom>Create Game</Typography>
                    <ToggleButtonGroup
                        value={league}
                        exclusive
                        onChange={handleLeagueChange}
                        aria-label="text alignment"
                    >
                        <ToggleButton value='NBA'>NBA</ToggleButton>
                        <ToggleButton value='NFL'>NFL</ToggleButton>
                        <ToggleButton value='MLB'>MLB</ToggleButton>
                    </ToggleButtonGroup>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1} padding={1} justifyContent="center" alignItems="top">
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Lineup Slots"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={lineupSlots}
                                    onChange={(e) => setLineupSlots(+(e.target.value))}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Number of Entries"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={entries}
                                onChange={(e) => setEntries(+(e.target.value))}
                                margin="normal"
                            />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Entry Price"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={entryPrice}
                                    onChange={(e) => setEntryPrice(+(e.target.value))}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Prize"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={prize}
                                    onChange={(e) => setPrize(+(e.target.value))}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Start Time (Unix Time)"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Close (Unix Time)"
                                    helperText="Time needs to be provided in unix time. See https://www.unixtimestamp.com/"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={closeTime}
                                    onChange={(e) => setCloseTime(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        

                        <Button type="submit" variant="contained" color="primary">
                            Create
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default GameModal;
