export interface GameConfig {
    gameConfigUid: string,
    title: string,
    startTime: number,
    closeTime: number,
    league: League,
    lineupSlots: number,
    entries: number,
    entryPrice: number,
    prize: number,
    questions: Question[]
    resolved: string
}
export const enum League {
    nba = "NBA",
    nfl = "NFL",
    mlb = "MLB",
}
export interface Question {
    uid: string,
    gameUid: string,
    questionText: string,
    abbreviation: string,
    selectionText: string,
    questionNumber: number,
    options: PlayerOptionResult[] 
}

export interface PlayerOptionResult {
    uid: string,
    league: string,
    playerUid: string,
    playerName: string,
    playerPosition: string,
    playerPositionAbbreviation: string,
    playerJersey: string,
    playerHeadshot: string,
    teamUid: string,
    teamName: string,
    teamAbbreviation: string,
    opponentUid: string,
    opponentName: string,
    opponentAbbreviation: string,
    stat: string,
    statValue: number,
    statAbbreviation: string,
    statQuantityAvg: number,
    oprk: number,
    gameTime: number,
}

export interface PlayerOptionRequest {
    league: string,
    playerUid: string,
    teamUid: string,
    opponentUid: string,
    statAvgAbbreviation: string,
    statQuantityAvg: number,
    oprk: number,
    gameTime: number,
    individualGameUid: string,
}

export interface Team {
    name: string,
    abbreviation: string,
    league: string,
    logo_url: string,
    uid: string
}

export interface Player {
    name: string,
    position: string, 
    jersey: string,
    headshot: string,
    headshot_downloaded: boolean,
    player_position_abbreviation: string,
    uid: string,
}

export interface TournamentConfig {
    active: boolean,
    entry_price: number,
    max_player_count: number,
    prizes: number[],
    uid: string
}
export interface Statistic {
    uid: string,
    league: League,
    name: string
    questionText: string,
    abbreviation: string, 
    sportsRadarStatName: string,
    avgAbbreviation: string
}

export interface User {
    name: string,
    createdAt: number,
    phoneNumber: string,
    updatedAt: number,
    uid: string
    active: boolean,
    balance: number,
    deviceToken: string | undefined
};

export interface TextMessage{
    message_id: string, 
    message_type: string,
    created_at: number,
    uid: string,
    to_user: boolean,
    data: object | undefined
}

export interface IndividualGame {
    uid: string,
    league: League,
    scheduled: number,
    season: number,
    status: string,
    homeUid: string,
    homeSportsRadarId: string,
    homeName: string,
    awayUid: string,
    awaySportsRadarId: string,
    awayName: string,
    sportsRadarGameId: string
}
