import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from './../defaults';
import { AuthContext } from '../auth/AuthContext';

interface AreYouSureDialogProps {
    gameId: string
}
function AreYouSureDialog({ gameId }: AreYouSureDialogProps) {
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    // Close the dialog
    setOpen(false);
    console.log(gameId)
    const user = authContext.user;
    if (user === null) {
        throw new Error('Need a an admin user object to make admin calls');
    }
    const idToken = await user.getIdToken()
    console.log(idToken)
    // Perform the axios request upon confirmation
    await axios.patch(`/internal/games/${gameId}/resolve`, {}, { headers: {
        "Authorization" : `Bearer ${idToken}`
    } } );
  };

  return (
    <div>
      <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
        Resolve Game
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to resolve this game?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AreYouSureDialog;
