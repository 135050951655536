import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "./../defaults";
import { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TextMessage, User } from "../interfaces";
import { userCache } from "./Users";


const columns: GridColDef[] = [
    { field: 'uid',
      headerName: 'UID',
      width: 200 
    },
    {
      field: 'message_id',
      headerName: 'Message Id',
      width: 150,
      editable: true,
    },
    {
      field: 'message_type',
      headerName: 'Message Type',
      type: 'number',
      width: 200,
      editable: true,
    },
    {
      field: 'sender',
      headerName: 'sender',
      type: 'string',
      width: 200,
      renderCell: (params) => {
        return (params.row.to_user ? 'Parlay' : 'User')
      }
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 800,
      renderCell: (params) => {
        if (params.row.data !== undefined) {
          const data = params.row.data;
          return (
            <div style={{ height: '100%', overflow: 'auto' }}>
              <Typography>{JSON.stringify(data, null, "\n")}</Typography>
            </div>
          )
        } else {
          return;
        }
      }
    }
  ];


function UserPage() {
    const userId = useParams<{ userId: string }>().userId!;
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState<TextMessage[]>([]);

    useEffect(() => {
      const fetchData = async () => {
          console.log(userId);
          try {
              if (userCache.has(userId)) {
                  setUser(userCache.get(userId));
                  const [messagesData] = await Promise.all([
                    axios.get<any[]>(`/users/${userId}/messages`)
                  ]);
                  setMessages(messagesData.data);
              } else {
                  const [userData, messagesData] = await Promise.all([
                      axios.get<User>(`/users/${userId}`),
                      axios.get<any[]>(`/users/${userId}/messages`)
                  ]);
                  
                  setUser(userData.data);
                  setMessages(messagesData.data);
              }
          } catch (error) {
              console.error('Error fetching data: ', error);
          } finally {
              setLoading(false);
          }
      };
      fetchData();
    }, [userId]);
  
    
    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <Suspense fallback={"Loading..."}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center">
            <Grid xs={"auto"}>
                <Typography variant="h3">{user?.name}</Typography>
            </Grid>
            <Grid xs={4}>
                <Typography variant="body1">{`Phone Number: ${user?.phoneNumber}`}</Typography>
            </Grid>
            <Grid xs={12}>
                <Typography variant="h4">Messages</Typography>
                <DataGrid getRowId={(row: { uid: any; }) => row.uid}
                    rows={messages!}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Grid>
        </Grid>
        </Suspense>
    )
}
export default UserPage;