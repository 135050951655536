import { Button, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import axios from '../defaults';
import { IndividualGame, Player, PlayerOptionRequest, Team, League } from '../interfaces';


interface AddPlayerOptionProps {
    options: PlayerOptionRequest[],
    avgAbbr: string,
    onAdd: (newOption: PlayerOptionRequest) => void
}

export function AddPlayerOption({options, avgAbbr, onAdd}: AddPlayerOptionProps) {
    
    const nullTeam = {
        abbreviation: "",
        league: "",
        logo_url: "",
        name: "", 
        uid: ""
    } as Team;

    const nullPlayer = {
        name: "",
        position: "", 
        jersey: "",
        headshot: "",
        headshot_downloaded: true,
        player_position_abbreviation: "",
        uid: "",
    } as Player;

    const nullGame = {
        uid: "",
        league: League.nba,
        scheduled: 0,
        season: 0,
        status: "",
        homeUid: "",
        homeSportsRadarId: "",
        homeName: "",
        awayUid: "",
        awaySportsRadarId: "",
        awayName: "",
        sportsRadarGameId: "",
    } as IndividualGame;


    const [isOpen, setIsOpen] = useState(false);
    const [league, setLeague] = useState('NBA');
    const [teams, setTeams] = useState<Team[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<Team>(nullTeam);
    const [schedule, setSchedule] = useState<IndividualGame[]>([]);
    const [selectedGame, setSelectedGame] = useState<IndividualGame>(nullGame);
    const [players, setPlayers] = useState<Player[]>([]);
    const [selectedPlayer, setSelectedPlayer] = useState<Player>(nullPlayer);
    const [statAvgName, setStatAvgName] = useState<String>(avgAbbr);
    const [statQuantityAvg, setStatQuantityAvg] = useState<String>("0.0");
    const [oprk, setOprk] = useState<Number>(0);
    const authContext = useContext(AuthContext);

    const handleOpen = () => {
        setIsOpen(true)
        setStatAvgName(avgAbbr)
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
          const user = authContext.user;
          if (user === null) {
            throw new Error('Need a an admin user object to make admin calls');
          }
          const idToken = await user.getIdToken();
          console.log('Fetching data...');
          try {
            let response = await axios.get(`/internal/teams/${league}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`
                }
            });
            const teamsList = response.data;
            let sortedTeams = teamsList.sort((n1: Team, n2: Team) => {
                if (n1.name > n2.name) {
                    return 1;
                }
            
                if (n1.name < n2.name) {
                    return -1;
                }
            
                return 0;
            }); 
            setTeams(sortedTeams);
          } catch (error) {
            console.error('Error fetching data: ', error);
          }
        };
        fetchData();
      }, [authContext, league]);
      
    const handleLeagueChange = async (_ : React.MouseEvent<HTMLElement>, league: string) => {
        setLeague(league);
        setSelectedTeam(nullTeam);
        setSchedule([]);
        const user = authContext.user;
        if (user === null) {
            throw new Error('Need a an admin user object to make admin calls');
        }
        const idToken = await user.getIdToken();
        try {
            let response = await axios.get(`/internal/teams/${league}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`
                }
            });
            const teamsList = response.data;
            let sortedTeams = teamsList.sort((n1: Team, n2: Team) => {
                if (n1.name > n2.name) {
                    return 1;
                }
            
                if (n1.name < n2.name) {
                    return -1;
                }
            
                return 0;
            }); 
            setTeams(sortedTeams);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const handleTeamChange = async (team: Team) => {
        if (team === nullTeam) {
            setPlayers([]);
            setSchedule([]);
            handlePlayerChange(nullPlayer);
        } else {
            const user = authContext.user;
            if (user === null) {
                throw new Error('Need a an admin user object to make admin calls');
            }
            const idToken = await user.getIdToken();
            try {
                let playersResponse = await axios.get(`/internal/teams/${league}/${team.uid}`, {
                    headers: {
                        "Authorization": `Bearer ${idToken}`
                    }
                });
                const playersList = playersResponse.data;
                let scheduleResponse = await axios.get(`/internal/teams/${league}/${team.uid}/schedule`, {
                    headers: {
                        "Authorization": `Bearer ${idToken}`
                    }
                });
                const schedule = scheduleResponse.data;
                let sortedPlayers = playersList.sort((n1: Player, n2: Player) => {
                    if (n1.name > n2.name) {
                        return 1;
                    }
                
                    if (n1.name < n2.name) {
                        return -1;
                    }
                
                    return 0;
                }); 
                let sortedSchedule = schedule.sort((n1: IndividualGame, n2: IndividualGame) => {
                    return (n1.scheduled > n2.scheduled) ? 1 : n1.scheduled === n2.scheduled ? 0 : -1
                })
                setPlayers(sortedPlayers);
                setSchedule(sortedSchedule);
                setSelectedGame(schedule[0]);                
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        }
    };

    const handlePlayerChange = async (player: Player) => {
        if (player !== undefined) {
            setSelectedPlayer(player);
        } else {
            setSelectedPlayer(nullPlayer);
        }
    }

    const handleSubmit = () => {
        if (selectedPlayer === nullPlayer || selectedTeam === nullTeam || selectedGame === nullGame) {
            return;
        }
        const playerOption = {
            league: league,
            playerUid: selectedPlayer.uid,
            teamUid: selectedTeam.uid,
            opponentUid: selectedGame.awayUid,
            statAvgAbbreviation: statAvgName,
            statQuantityAvg: +(statQuantityAvg),
            oprk: oprk,
            individualGameUid: selectedGame.uid,
        } as PlayerOptionRequest;
        onAdd(playerOption);
        handleClose();
    }

    return (
        <>
        <Button variant="outlined" onClick={handleOpen}>
            Add Player
        </Button>
        <Dialog open={isOpen} onClose={handleClose}>
            <Grid spacing={1}>
                <Typography variant="h6" gutterBottom>Add Player</Typography>
                <ToggleButtonGroup
                    value={league}
                    exclusive
                    onChange={handleLeagueChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value='NBA'>NBA</ToggleButton>
                    <ToggleButton value='NFL'>NFL</ToggleButton>
                    <ToggleButton value='MLB'>MLB</ToggleButton>
                </ToggleButtonGroup>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedTeam ? selectedTeam.uid : ""}
                        label="Team"
                        onChange={(e) => {
                            const teamId = e.target.value; // This will be a string
                            const team = teams.find(team => team.uid === teamId);
                            setSelectedTeam(team !== undefined ? team : nullTeam);
                            handleTeamChange(team !== undefined ? team : nullTeam);
                        }}
                    >
                    {teams.map((team)=> (
                        <MenuItem key={team.uid} value={team.uid}>
                            {team.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Players</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPlayer?.uid ?? ""}
                        label="Players"
                        onChange={(e) => {
                            const playerId = e.target.value; // This will be a string
                            const player = players.find(player => player.uid === playerId);
                            handlePlayerChange(player !== undefined ? player : nullPlayer);
                        }}
                    >
                    {players.map((player)=> {
                        console.log(player)
                        return (<MenuItem key={player.uid} value={player.uid}>
                            {`${player.name} - ${player.position}`}
                        </MenuItem>
                    )})}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Game</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedGame ? selectedGame.uid : ""}
                        label="Game"
                        onChange={(e) => {
                            const selectedGame = e.target.value; // This will be a string
                            const game = schedule.find(game => game.uid === selectedGame);
                            setSelectedGame(game !== undefined ? game : nullGame);
                        }}
                    >
                    {schedule.map((game)=> (
                        <MenuItem key={game.uid} value={game.uid}>
                            {`${game.homeName} vs ${game.awayName} on ${new Date(game.scheduled * 1000)}`}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <Grid xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Stat Avg Abbreviation"
                        value={statAvgName}
                        onChange={(e) => setStatAvgName(e.target.value)}
                        margin="normal"
                    />
                </Grid>
                <Grid xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Stat Quantity Average"
                        value={statQuantityAvg}
                        onChange={(e) => setStatQuantityAvg(e.target.value)}
                        margin="normal"
                    />
                </Grid>
                <Grid xs={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Opponents Rank"
                        value={oprk}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange={(e) => setOprk(+(e.target.value))}
                        margin="normal"
                    />
                </Grid>
                <Button 
                    variant="contained" 
                    color="primary" 
                    sx={{ml: 2}}
                    onClick={handleSubmit}
                >
                    Add Player
                </Button>
            </Grid>
            </Dialog>
        </>
    );
}

export default AddPlayerOption;

