import { Box, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from './../defaults'
import React, { useContext, useState } from 'react';
import { User } from '../interfaces';
import { AuthContext } from '../auth/AuthContext';

interface MessageProps {
    user: User
}

function SendMessageModal({user}: MessageProps)  {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [body, setBody] = useState('');
    const authContext = useContext(AuthContext);

    const handleOpen = () => {
        console.log(user);

        setIsOpen(true)};
    const handleClose = () => setIsOpen(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (title === '' && subtitle === '' && body === '') {
            return;
        }
        const adminUser = authContext.user;
        if (adminUser === null) {
          throw new Error('Need a an admin user object to make admin calls');
        }
        const idToken = await adminUser.getIdToken();

        try {
            const response = await axios.post<string>(`/internal/users/notification`,{
                title: title, 
                subtitle: subtitle, 
                body: body,
                deviceIds: [user.deviceToken!]
            }, { headers: {
                "Authorization" : `Bearer ${idToken}`
              } } );
            console.log(response);
        } catch (e:any) {
            console.log(e instanceof Error ? e.message : e);

        }
        
        handleClose();
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        outline: 'none',
      };
    

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Send Push Notification
            </Button>
            <Modal open={isOpen} onClose={handleClose}>
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Send Notification
                </Typography>
                <Stack spacing={2} mt={2}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    label="Subtitle"
                    variant="outlined"
                    fullWidth
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                />
                <TextField
                    label="Body"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                />
                <Button variant="contained" onClick={handleSubmit}>
                    Send Notification
                </Button>
                </Stack>
            </Box>
        </Modal>
        </>
    );
};

export default SendMessageModal;