import { AppBar, IconButton, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './auth/AuthContext';
import { auth } from '.';


  
function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const authContext = useContext(AuthContext);
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // Implement logout logic using Firebase
    auth.signOut();
    handleClose();
  };
  const handleCopyToken = async () => {
    // Retrieve the token and copy to clipboard
    const token = await authContext.user?.getIdToken();
    if (token) {
      navigator.clipboard.writeText(token);
    }
    handleClose();
  };


  return (
    <AppBar position="static">
      <Tabs value={false}>
        <Link to="/">
          <IconButton edge="start" color="inherit" aria-label="logo">
            <img src={`${process.env.PUBLIC_URL}/parlay_logo.jpg`} alt="Logo" height="40" />
          </IconButton>
        </Link>
        <Tab label="Home" component={Link} to="/" />
        <Tab label="Users" component={Link} to="/users" />
        <Tab label="Games" component={Link} to="/games" />
        <Tab label="Tournaments" component={Link} to="/tournaments"/>
        <Tab label="Livestream" component={Link} to="/livestream"/>
        <IconButton
        edge="end"
        color="inherit"
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        style={{ marginLeft: 'auto', marginRight: '15px' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCopyToken}>Copy Token</MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
      </Tabs>
    </AppBar>
  );
};

export default Header;