import { useContext, useState } from 'react';
import { AuthContext } from './auth/AuthContext';
import { signInWithGoogle } from './auth/auth';

export function Home() {
  const [error,] = useState('');
  const { user } = useContext(AuthContext);
  const handleSignIn = () => {
    signInWithGoogle();
  };
  // Redirect if already logged in
  if (user) {
    return (
      <div>
        <h2>Login</h2>
        {error && <p>{error}</p>}
        <h2>Home Page</h2>
        <h4>{`Running in ${process.env.REACT_APP_STAGE}`}</h4>
      </div>
    );
  } else {
    return (
      <button onClick={handleSignIn}>Sign In with Google</button>
    );
  }
}
;
