import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {initializeApp} from 'firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB0e-6779w7TZbRBcRfThVkJec7S11_XWs",
  authDomain: "parlay-3f6b7.firebaseapp.com",
  projectId: "parlay-3f6b7",
  storageBucket: "parlay-3f6b7.appspot.com",
  messagingSenderId: "187911683589",
  appId: "1:187911683589:web:1482ec856dfaac6770a66c"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
