import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound: React.FC = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>404 - Page Not Found</h1>
            <p style={styles.text}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <Link to="/" style={styles.link}>Go to Home Page</Link>
        </div>
    );
};

// Styles as React.CSSProperties
const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#f8f8f8'
    },
    header: {
        color: '#d9534f',
        fontSize: '42px',
        margin: '20px 0'
    },
    text: {
        color: '#333',
        marginBottom: '20px'
    },
    link: {
        color: '#007bff',
        textDecoration: 'underline'
    }
};

export default PageNotFound;
