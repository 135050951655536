import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from './../defaults';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GameConfig } from '../interfaces';
import GameModal from './GameModal';
import { AuthContext } from '../auth/AuthContext';


export const gameCache: Map<string, GameConfig> = new Map();

const columns: GridColDef[] = [
    { field: 'title',
      headerName: 'Title',
      width: 250 
    },
    {
      field: 'startTime',
      headerName: 'Start Time', 
      width: 200,
      renderCell: (params) => {
        return new Date(params.row.startTime * 1000).toLocaleString()
      }
    },
    {
      field: 'closeTime',
      headerName: 'Ending Time', 
      width: 200,
      renderCell: (params) => {
        return new Date(params.row.closeTime * 1000).toLocaleString()
      }
    },
    {
        field: 'entries',
        headerName: 'Entries',
        width: 100,
    },
    {
      field: 'lineupSlots',
      headerName: 'Lineup Slots',
      width: 100,
  },
    {
      field: 'entryPrice',
      headerName: 'Entry Price',
      width: 100,
    },
    {
      field: 'prize',
      headerName: 'Prize',
      width: 100,
    },
    {
      field: 'league',
      headerName: 'League',
      width: 100,
    },
    {
      field: 'resolved',
      headerName: 'Resolved',
      width: 100,
    },
    {
      field: 'questions',
      headerName: 'Questions',
      width: 200,
      renderCell: (params) => {
        const onClick = () => {
          gameCache.set(params.row.gameConfigUid, params.row)
          return params.row.gameConfigUid;
        };
        return (
            <Button component={Link} variant="outlined" to={`${onClick()}/questions`}>Questions</Button>        
        )
      }
    }
  ];

function Games() {
    const [games, setGames] = useState([]);
    const authContext = useContext(AuthContext);
    const fetchData = useCallback( async () => {
      const user = authContext.user;
      if (user === null) {
        throw new Error('Need a an admin user object to make admin calls');
      }
      const idToken = await user.getIdToken();
      console.log('Fetching data...');
      try {
        const response = await axios.get(`/internal/games/all`, { headers: {
          "Authorization" : `Bearer ${idToken}`
        } } );
        setGames(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }, [authContext.user]);
    useEffect(() => {
      fetchData();
    }, [authContext, fetchData]);

  const useModalSubmit = async () => {
    fetchData()
  }
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <Typography variant="h3">Games</Typography>
      <DataGrid getRowId={(row) => row.gameConfigUid}
        rows={games}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      <GameModal onSubmit={useModalSubmit}></GameModal>
    </Box>
  );
};

export default Games;