import { GoogleAuthProvider, getAuth, signInWithRedirect, signOut } from 'firebase/auth';
import { auth } from '..';

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  const authInstance = getAuth();
  signInWithRedirect(authInstance, provider);
};

export const logOut = async () => {
  return await signOut(auth);
};