import { Box, Grid, List, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import axios from './../defaults';
import AddPlayerOption from './AddPlayerToQuestion';
import { PlayerOptionRequest, Question, Statistic } from '../interfaces';

interface QuestionModalProps {
    gameUid: string;
    onAdd: (questions: Question) => void
}

function QuestionModal({gameUid, onAdd}: QuestionModalProps)  {
    const [isOpen, setIsOpen] = useState(false);
    const [statistics, setStatistics] = useState<Statistic[]>([]);
    const [selectedStatistic, setSelectedStatistic] = useState<Statistic | undefined>();
    const [selectionText, setSelectionText] = useState('Select 1');
    const [options, setOptions] = useState<PlayerOptionRequest[]>([]);
    
    const handleOpen = async () => {
        setIsOpen(true)
        const user = authContext.user;
        if (user === null) {
            throw new Error('Need a an admin user object to make admin calls');
        }
        const idToken = await user.getIdToken();
        const response = await axios.get(`/statistics`, {headers: {
            "Authorization" : `Bearer ${idToken}`
        }})
        const stats = response.data;
        setStatistics(stats);
    };
    const authContext = useContext(AuthContext);
    const handleClose = () => {
        setIsOpen(false);
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Your logic to create a new game with the input (gameName)
        try {
            const user = authContext.user;
            if (user === null) {
                throw new Error('Need a an admin user object to make admin calls');
            }
            const idToken = await user.getIdToken();
            console.log("Creating question with name:", selectedStatistic?.questionText, options);
            const data = {
                "questionText": selectedStatistic?.questionText,
                "selectionText": selectionText,
                "statAbbreviation": selectedStatistic?.abbreviation,
                "sportsRadarStatName": selectedStatistic?.sportsRadarStatName,
                "playerOptions": options,
              };
            const question = await (axios.post(`/internal/games/${gameUid}/question`, data, { 
                headers: {
                    "Authorization" : `Bearer ${idToken}`
                }
            }))
            onAdd(question.data)
        }catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            handleClose();
        }
    };
    const handleSetSelectedStatistic = (_event: React.MouseEvent<HTMLElement, MouseEvent>, s: Statistic) => {
        setSelectedStatistic(s)
    }

    const addPlayerOption = (newOption: PlayerOptionRequest) => {
        setOptions(prevOptions => [...prevOptions, newOption]);
    };

    const removePlayerOption = (index: number) => {
        setOptions(prevOptions => prevOptions.filter((_, idx) => idx !== index));
    };


    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Create Question
            </Button>
            <Modal open={isOpen} onClose={handleClose}>
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: 300,
                    }}
                >   
                    <Typography variant="h6" gutterBottom>Create Question</Typography>
                    <ToggleButtonGroup
                        value={selectedStatistic}
                        exclusive
                        onChange={handleSetSelectedStatistic}
                        aria-label="text alignment"
                    >
                        {statistics.map((stat) => {
                            return (
                                <ToggleButton value={stat}>{stat.name}</ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                    <form onSubmit={handleSubmit}>
                        <Grid spacing={1}>
                            <Grid xs={12}>
                                <Typography>{`Question Text: ${selectedStatistic?.questionText}`}</Typography>
                                
                            </Grid>
                            <Grid xs={6}>
                                <Typography>{`Abbreviation: ${selectedStatistic?.abbreviation}`}</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography>{`Sports Radar Stat Name: ${selectedStatistic?.sportsRadarStatName}`}</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Selection Text"
                                    value={selectionText}
                                    onChange={(e) => setSelectionText(e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                            <AddPlayerOption options = {options} avgAbbr={selectedStatistic?.avgAbbreviation ?? ""} onAdd={addPlayerOption} />
                            <List sx={{ borderRadius: 1 }}>
                                {options.map((option, index) => ( 
                                    <div key={index}>
                                        {option.playerUid}
                                        <button onClick={() => removePlayerOption(index)}>Remove</button>
                                    </div>
                                ))}
                            </List>
                        </Grid>
                    <Button type="submit" variant="contained" color="primary" disabled={selectedStatistic === undefined || options.length <=2 }>
                        Create
                    </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default QuestionModal;
