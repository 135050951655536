import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound';
import { AuthContext } from '../auth/AuthContext';
import { GameConfig, Question } from '../interfaces';
import axios from './../defaults';
import OptionsModal from './OptionsModal';
import QuestionModal from './QuestionModal';
import AreYouSureDialog from './AreYouSureDialog';
import { Box, Grid } from '@mui/material';

type GetColumnsParams = {
    game: GameConfig | null;
};

export const getColumns = ({ game }: GetColumnsParams): GridColDef[] => [
    {
      field: 'questionNumber',
      headerName: 'Number',
      width: 100,
      editable: true,
    },
    { field: 'uid',
      headerName: 'UID',
      width: 200 
    },
    {
      field: 'questionText',
      headerName: 'Question',
      width: 200,
      editable: true,
    },
    {
      field: 'selectionText',
      headerName: 'Selection Text',
      width: 200,
      editable: true,
    },
    {
      field: 'abbreviation',
      headerName: 'Abbreviation',
      width: 100,
      editable: true,
    },
    {
      field: 'options',
      headerName: 'Options',
      width: 150,
      renderCell: (params) => {
        return (
        <OptionsModal 
            optionsProp={params.row.options} gameProp={game!} questionProp={params.row}/>)
      }
    }
  ];

function Questions() {
    const gameId = useParams<{ gameId: string }>().gameId!;
    const [game, setGame] = useState<GameConfig | null>(null);
    const [loading, setLoading] = useState(true);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const user = authContext.user;
            if (user === null) {
              throw new Error('Need a an admin user object to make admin calls');
            }
            const idToken = await user.getIdToken()
            const gameResponse = await axios.get(`/games/game/${gameId}/results`, { headers: {
              "Authorization" : `Bearer ${idToken}`
            } } );
            console.log(gameResponse.data)
            setGame(gameResponse.data);
            
          } catch (error) {
            console.error('Error fetching data: ', error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }, [gameId, authContext.user]);
      if (!loading && (gameId === undefined || game === null)) {
        return (
            <PageNotFound/>
        )
    }
    const columns = getColumns({game});
    const onAdd = (questions: Question) => {
     game?.questions.push(questions)
    }
    if (loading) {
        return <p>Loading...</p>;
    }
  return (
    <Box sx={{ width: '100%' }} >
      <Typography variant="h3">{`${game?.title} - ${new Date((game?.closeTime ?? 0) * 1000).toLocaleString()}`}</Typography>
      <DataGrid getRowId={(row) => row.uid}
        rows={game?.questions ?? []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
          sorting: {
            sortModel: [{ field: 'questionNumber', sort: 'asc' }],
          },
        }}
        pageSizeOptions={[15]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      <Grid container>
        <Grid item xs={2}>
        <QuestionModal gameUid={game!.gameConfigUid} onAdd={onAdd}></QuestionModal>
        </Grid>
        <Grid item xs={2}>
          <AreYouSureDialog gameId={game!.gameConfigUid}></AreYouSureDialog>
        </Grid>
    </Grid>
    </Box>
  );
};

export default Questions;